@import "_home.scss";

.topo.topo--internas ~ .wrapper{
	background-color:#010101;
	color:#FFF;
	position:relative;
	z-index:10;

	&:before{
		content:'';
		display:none;
		position:absolute;
		bottom:-54px;
		right:0;
		background-image:url('../images/bg-internas-logo.png');
		width:604px;
		z-index:-2;
		height:585px;
		animation:fadeInUp 1s linear;

		@include media-breakpoint-up(md){
			display:block;
		}

	}

}

.internas{	
	padding-top:106px;
	padding-bottom:54px;
	z-index:100;
	position:relative;
	min-height:90vh;

	& > .container{
		position:relative;
		z-index:60;
	}

	.internas__ul-programas{
		list-style-type:none;
		padding-left:0;
		margin-bottom:52px;
		text-align:center;

		@include media-breakpoint-up(md){
			text-align:left;
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
		}

		li{
			margin-bottom:10px;
		}

	}

	&.internas-enjoy{
		padding-top:50px;

		.internas-enjoy__box-text{
			position:relative;

			&:before{
				content:'';
				display:block;
				position:absolute;
				width:33px;
				height:33px;
				background-repeat:no-repeat;
				background-position:center center;
				background-image:url('../images/circle-custom-1.png');
				bottom:calc(0% - 40px);
				right:calc(0% - 60px);
				transform:rotate(40deg);
				animation:bolinhaLado 5s alternate infinite;
			}

		}

	}

	&.internas-modalidades{

		.row-modalidades{
			position:relative;

			&:before{
				content:'';
				display:block;
				width:33px;
				height:33px;
				background-image:url('../images/circle-custom-3.png');
				background-repeat:no-repeat;
				position:absolute;
				top:54px;
				right:calc(100% + 142px);
			}

		}

	}

}