.banner{
	text-align:center;

	.carousel-item{
		margin-bottom:0;
	}

	.banner__arrow-down{
		position:absolute;
		bottom:90px;
		left:50%;
		transform:translateX(-50%);
		background-image:url('../images/bg-banner-arrow-down.png');
		width:84px;
		height:88px;
		z-index:220;

		@include media-breakpoint-down(sm){
			bottom:0;
		}

	}

	.banner__controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;
		padding-bottom:31px;

		.clearfix{
			width:100%;
			max-width:1240px;
			margin-left:auto;
			margin-right:auto;

			& > *{
				background-image:url('../images/bg-banner-control.png');
				width:74px;
				height:62px;
				font-size:11px;
				font-family: 'montserratregular';
				letter-spacing:2px;
				color:#FFF;
				padding:16px;
				position:relative;
				z-index:220;
				display:inline-flex;
				align-items:center;
				justify-content:center;

				&:hover{
					text-indent:10px;
				}

				&[data-slide="prev"]{

					&:hover{
						text-indent:-10px;
					}

				}

			}

		}

	}

}

.bem-vindo{
	background-color:#111111;
	color:#FFF;
	background-repeat:no-repeat;
	background-size:cover;
	background-attachment:fixed;
	padding-bottom:1px;
	text-align:center;
	position:relative;
	z-index:5;
	animation:bg-cima 8s infinite alternate;

	.bem-vindo__mao{

		&:before{
			content:'';
			display:none;
			position:absolute;
			left:calc(100% - 302px);
			background-image:url('../images/bg-internas-logo.png');
			width:604px;
			height:585px;
			top:54px;

			@include media-breakpoint-up(lg){
				display:block;
			}

		}

	}
	
	& > .container{
		padding-bottom:50px;
		position:relative;
		padding-top:86px;

		@include media-breakpoint-up(lg){
			padding-bottom:573px;
		}

		.max-height-bem-vindo{

			@include media-breakpoint-up(lg){
				min-height:120px;
				max-height:132px;
			}

		}

		&:after{
			content:'';
			display:none;
			background-repeat:no-repeat;
			position:absolute;
			background-image:url('../images/bem-vindo-mulher.png');
			width:891px;
			height:1000px;
			top:136px;
			left:calc(0% - 236px);

			@include media-breakpoint-up(lg){
				display:block;
			}

		}

		.bem-vindo__content{
			max-width:850px;
			margin-left:auto;
			margin-right:auto;
			position:relative;
			z-index:20;

			@include beforeAfter{
				content:'';
				display:none;
				width:33px;
				height:33px;
				position:absolute;

				@include media-breakpoint-up(lg){
					display:block;
				}

			}

			&:before{
				background-image:url('../images/circle-custom-4.png');
				top:calc(100% - 6px);
				right:190px;
				animation:bolinhaLado 5s alternate infinite;
			}

			&:after{
				background-image:url('../images/circle-custom-5.png');
				top:calc(100% + 138px);
				right:calc(50% + 160px);
				animation:bolinhaLadoInverso 5s alternate infinite;
			}

		}

	}

	.header-section{
		margin-bottom:31px;

		&.header-section--interna-enjoy{

			&:before{
				left:-60px;
				bottom:unset;
				top:0;
				animation:bolinha1 5s alternate infinite;
			}

		}

	}

	.container.animou{

		&:after{
			animation:zoomInLeft 1s linear;
			animation-fill-mode: both;
		}

	}

}

.vantagens{
	padding-top:20px;
	padding-bottom:2px;
	background-repeat:no-repeat;
	background-position:center bottom;
	z-index:100;
	position:relative;

	@include media-breakpoint-down(md){
		background-size:cover;
		background-attachment:fixed;
	}

	.container{
		position:relative;

		@include beforeAfter{
			content:'';
			display:none;
			position:absolute;
			z-index:1000;

			@include media-breakpoint-up(lg){
				display:block;
			}

		}

		&:before{
			// top:calc(0% - 164px);
			// height:134px;
			// width:308px;
			// left:-60px;
			// background-image:url('../images/mao-mulher.png');
		}

		&:after{
			width:723px;
			height:234px;
			top:-280px;
			right:10px;
			background-image:url('../images/bg-choose.png');
			animation:tada 4s infinite alternate;
		}

	}

	&:before{
		content:'';
		display:none;
		position:absolute;
		bottom:calc(100% - 98px);
		left:0;
		pointer-events:none;
		width:100%;
		background-image:url('../images/bg-vantagens-original.png');
		background-position:top center;
		z-index:-2;
		height:780px;

		@include media-breakpoint-up(lg){
			display:block;
		}

	}

	@include media-breakpoint-up(lg){
		margin-top:-104px;
	}

	.header-section{
		min-height:140px;

		.header-section__title{
			max-height:110px;
			
			@include media-breakpoint-up(lg){
				@include line-clamp(2);
			}

		}

		.header-section__subtitle{
			
			@include media-breakpoint-up(lg){
				@include line-clamp(1);
			}

		}

	}

}

.video-incrivel{
	background-color:$theme;
	max-height:550px;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	background-attachment:fixed;
	cursor:pointer;
	position:relative;

	.video-incrivel__controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;

		.icon{
			position:relative;

			&:before{
				content:'';
				display:block;
				background-color:rgba(#000,0.7);
				border-radius:100%;
				position:absolute;
				top:calc(50% - 5px);
				left:calc(50% - 5px);
				width:10px;
				height:10px;
				opacity:0;
				animation:explodeMidia 3s infinite;
			}

		}

	}

}

.desafios{
	background-color:#010101;
	padding-top:90px;
	background-repeat:no-repeat;
	background-attachment:fixed;
	background-size:cover;
	position:relative;

	&:before{
		content:'';
		display:block;
		width:446px;
		height:445px;
		position:absolute;
		top:0;
		left:50%;
		z-index:0;
		transform:translateX(-50%);
		background-image:url('../images/bg-header-desafios.png');
	}

	.header-section{
		position:relative;
		z-index:20;
	}

	.card-desafios{
		margin-bottom:0;
	}

	&.active{

		&:before{
			animation:fadeIn 1s linear;
		}

	}

}

.treino{
	padding-top:60px;
	padding-bottom:82px;
	background-color:#FAE453;
	color:#FFF;

	.container{
		position:relative;
		z-index:5;
	}

}

.grade-horaria{
	padding-top:98px;
	padding-bottom:68px;
	background-position:right bottom;
	background-size:cover;
	background-attachment:fixed;
	position:relative;
	color:#FFF;

	.grade-horaria__content{
		max-width:746px;
		margin-left:auto;
	}

	&:before{
		content:'';
		display:none;
		position:absolute;
		top:calc(0% - 334px);
		left:0;
		width:100%;
		min-height:877px;
		background-size:cover;
		background-image:url('../images/bg-triangulo-horario.png');
		background-color:#FAE453;

		@include media-breakpoint-up(lg){
			display:block;
		}

	}

	&:after{
		content:'';
		display:none;
		position:absolute;
		top:-682px;
		left:0;
		width:970px;
		z-index:0;
		height:760px;
		background-image:url('../images/bg-logo-treino.png');

		@include media-breakpoint-up(lg){
			display:block;
		}

	}

	@include media-breakpoint-down(md){
		background-color:#010101;
	}

	.grade-horaria__figure{
		position:relative;

		img{
			animation:tada 4s infinite alternate;
		}

		@include beforeAfter{
			content:'';
			display:block;
			position:absolute;
			width:33px;
			height:33px;
			background-image:url('../images/circle-custom-1.png');
		}

		&:before{
			bottom:calc(100% + 50px);
			right:36px;
			animation:bolinha1 5s alternate infinite;
		}

		&:after{
			left:-60px;
			bottom:-23px;
			animation:bolinhaLado 5s alternate infinite;
		}

	}

	.container{

		.img-homem{
			content:'';
			display:none;
			pointer-events:none;
			position:absolute;
			top:-12px;
			left:0;
			width:913px;
			height:554px;
			z-index:5;

			@include media-breakpoint-up(lg){
				display:block;
			}

		}

	}

	&.active{

		&:after{
			animation:fadeInLeft 1s linear;
		}

		.container{

			&:before{
				animation:pulse 2s alternate infinite;
			}

		}

	}

}

.galeria{
	position:relative;
	z-index:20;

	.container-fake{

		@include media-breakpoint-down(md){
			@include make-container();
			@include make-container-max-widths();
		}

		@include media-breakpoint-up(lg){
			display:flex;
		}

	}

	.galeria__figure-principal{
		position:relative;
		margin-bottom:0;
		max-width:749px;
		height:100%;
		background-size:cover;
		min-height:463px;
		background-position:center;

		@include media-breakpoint-up(lg){
			width:(749 / 1920) * 100%;
		}

		.galeria-figure-principal__content{
			position:absolute;
			padding:15px;
			top:0;
			right:0;
			max-width:388px;
			margin-left:auto;
			display:flex;
			align-items:flex-end;
			justify-content:center;
			height:100%;
			flex-direction:column;
			text-align:right;

			.galeria-figure-principal__title{
				font-family: 'montserratbold';
				line-height:1;
				letter-spacing:8px;
				margin-bottom:32px;

				@include media-breakpoint-up(md){
					font-size:59px;
				}

			}

		}

	}

	.segura-carousel{

		@include media-breakpoint-up(lg){
			width:(1171 / 1920) * 100%;
		}

		.slick-track{
			
			@include media-breakpoint-up(lg){
				max-height:463px;
			}

		}

		.slick-slide{

			@include media-breakpoint-up(lg){
				width:auto !important;
			}

		}

	}

}