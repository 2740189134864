.header-section{
	font-size:26px;
	margin-bottom:42px;

	@include media-breakpoint-up(md){
		font-size:50px;
	}

	& > *{
		margin-bottom:0;
		line-height:1.1;
	}

	.header-section__title{
		font-size:1em;
		font-family: 'montserratblack';
	}

	.header-section__subtitle{
		font-size:(20 / 50) * 1em;
		letter-spacing:6px;

		@include media-breakpoint-down(sm){
			font-size:18px;
		}

		@include media-breakpoint-up(md){
			letter-spacing:22px;
		}

	}

	&.sombra{

		& > *{
			text-shadow:0 0 4px rgba(#000,0.8);
		}

	}

}

.internas__header-section{
	color:#FAE453;
	text-align:center;
	position:relative;

	.header-section__title{
		letter-spacing:2px;
	}

	@include beforeAfter{
		content:'';
		position:absolute;
		width:33px;
		height:33px;
		background-repeat:no-repeat;
		background-position:center center;

	}

	&:before{
		background-image:url('../images/circle-custom-1.png');
		bottom:calc(100% + 15px);
		left:64px;
		display:block;
		animation:bolinhaLado 5s alternate infinite;
	}

	&:after{
		background-image:url('../images/circle-custom-2.png');
		bottom:0;
		right:80px;
		display:none;
		animation:bolinhaLadoInverso 5s alternate infinite;

		@include media-breakpoint-up(lg){
			display:block;
		}

	}

	.header-section__title{
		margin-bottom:8px;
	}

	&.header-section--interna-enjoy{
		margin-bottom:32px;

		.header-section__subtitle{
			letter-spacing:3px;
			margin-bottom:15px;

			&.header-section__subtitle-secundario{
				letter-spacing:22px;
			}

		}

		&:before{
			background-image:url('../images/circle-custom-3.png');
			bottom:calc(100% - 60px);
			left:190px;
			animation:bolinhaLado 5s alternate infinite;

			@include media-breakpoint-down(md){
				display:none;
			}

		}

		&:after{
			display:none;
		}

	}

	&.header-section--sem-before-after{

		@include beforeAfter{
			display:none;
		}

	}

}