.box-numeros{
	text-align: center;
	margin-bottom:80px;
	transition:transform 1s linear;

	/*
		698 = 100%;
		775 = 75%;
		850 = 50%
		925 = 25%
		1000 = 0%

		1000 - (var * 3);

	*/

	&.active{
		.circulo{
			svg{
				circle {
					--perimeter-active: var(--perimeter);
					stroke-dashoffset: var(--perimeter-active);
					transition: all 2s linear;
				}
			}
		}
	}

	.circulo{
		position: relative;
		height: 236px;
		width: 236px;
		max-width: 100%;
		max-height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;

		.number{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			font-size:70px;
			font-family: 'montserratblack';
			color:$theme;
			-webkit-background-clip: text;
  			-webkit-text-fill-color: transparent;
  			@include gradient-y(#F7DA58,#E8BE50);
		}

		.nome{
			position:absolute;
			bottom:38px;
			max-width:142px;
			max-height:48px;
			min-height:48px;
			overflow:hidden;
			left:50%;
			font-size:17px;
			font-family: 'montserratlight';
			line-height:1.2;
			color:#FFF;
			transform:translateX(-50%);
		}

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			width: 100%;
			height: 100%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: transparent;
			border: 10px solid #292929;
			border-radius: 50%;
		}

		svg{
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;

			circle{
				stroke-dasharray: var(--perimeter,301.106);
				stroke-dashoffset: var(--perimeter,301.106);
				transform-origin: center center;
				transform: rotate(-90deg);
			}
		}

		
	}

	&:hover{

		transform:translateY(-10px);

	}

}