.redes-sociais{

	& > *{
		@include margin(0,5);

		&:hover{
			color:$theme;
		}

	}

	&.rodape-principal__redes-sociais{
		margin-bottom:18px;

		& > *{
			font-size:22px;
			color:#000000;
			@include margin(0,9);
		}

	}

}