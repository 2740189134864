.media-custom{
	margin-bottom:10px;

	@include media-breakpoint-up(lg){
		display:flex;
	}

	.media-custom__img{
		margin-bottom:16px;

		@include media-breakpoint-up(lg){
			margin-right:25px;
			margin-bottom:0;
		}

	}

	.media-custom__text{

		
		
	}

}