#loading-page {
  position:fixed;
  top:0;
  left:0;
  z-index:1000;
  width:100%;
  height:100%;
  background-color:$theme;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;

  img{
    animation:tada 2s alternate infinite;
  }

  &.active{
    display:flex !important;
  }

}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}