.rodape{
	position:relative;
	z-index:600;

	.rodape__fixo-whats{
		position:fixed;
		z-index:900;
		
		@include media-breakpoint-up(lg){
			bottom:35px;
			right:35px;

		}

		@include media-breakpoint-down(md){
			bottom:5px;
			right:5px;
		}

		.btn--whatsapp{
			text-align:center;
			font-size:25px;
			width:(80 / 25) * 1em;
			height:(80 / 25) * 1em;
			line-height:(80 / 25) * 1em;
			color:#FBE454;
			background-color:#111;
			border-radius:100%;
			border:none;
			z-index:700;

			@include media-breakpoint-down(md){
				font-size:18px;
			}

			&[aria-expanded="true"]{
				animation:fadeOutRight 1s forwards;
			}

			&[aria-expanded="false"]{
				animation:fadeInRight 0.6s linear forwards;
			}

		}

		.btn--close{
			position:fixed;
			right:-100vw;
			bottom:15px;
			font-size:20px;
			width:(34 / 20)	* 1em;
			height:(34 / 20) * 1em;
			line-height:(34 / 20) * 1em;
			border-radius:100%;
			background-color:#000000;
			color:#FFF;
			padding:0;
			border:none;
			box-shadow:none;
			transition:opacity 0.6s, right 0.6s linear;

			&[aria-expanded="true"]{
				opacity:1;
				right:15px;
				animation:fadeInRight 0.6s linear;
			}

			&[aria-expanded="false"]{
				opacity:0;
				right:-50vw;
			}

		}

	}

	.rodape__menu-whatsapp{
		list-style-type:none;
		margin-bottom:0;
		padding-left:0;
		max-width:330px;
		position:relative;

		@include media-breakpoint-down(md){
			position:fixed;
			top:0;
			z-index:1000;
			transition:all 0.6s linear;
			left:-230px;
			overflow:auto;
			left:0;
			width:230px;
			height:100%;
		}

		li{

			&.li--header{
				overflow:hidden;
				background-color:#111111;
				color:#FFF;
				font-size:14px;
				font-family: 'montserratbold';
				text-align:center;
				padding:25px 30px;

				@include media-breakpoint-up(lg){
					border-radius:30px 30px 0 0;
				}

			}

			&.li--content{
				padding:18px 15px;
				background-color:#FFF;
				overflow:hidden;
				height:100%;

				.rodape-menu-whatsapp__content{
					
					@include media-breakpoint-up(lg){
						overflow:auto;
						max-height:60vh;
					}

				}

				@include media-breakpoint-up(lg){
					border-radius:0 0 30px 30px;
				}

				.media-custom{
					margin-bottom:13px;
					color:#444;
					align-items:center;
					transition:background-color 0.6s linear;
					border-radius:30px;

					@include media-breakpoint-down(md){
						margin-bottom:30px;
					}

					&:hover{
						background-color:#eee;
					}

					.media-custom__img{
						position:relative;

						@include media-breakpoint-up(lg){
							margin-right:8px;
						}

						&:before{
							content:'';
							display:block;
							font-size:14px;
							width:1em;
							position:absolute;
							bottom:2px;
							right:3px;
							z-index:20;
							border-radius:100%;
							background-color:#05DC05;
							height:1em;
						}

						&.ausente{

							&:before{
								background-color:orange;
							}

						}

						&.indisponivel{

							&:before{
								background-color:#444;
							}

						}

						img{
							border-radius:100%;
							overflow:hidden;
						}

					}

					.media-custom__text{

						& > *{
							line-height:1.2;
						}

						@include media-breakpoint-down(md){
							padding-top:15px;
						}

						.media-custom__title{
							color:#111;
							font-family: 'montserratbold';
						}

					}

				}

			}

		}

	}

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.rodape__logo{
		margin-bottom:26px;
		position:relative;
		z-index:60;
	}

	.rodape__principal{
		background-color:#FAE453;
		color:#000000;
		
		.container{
			position:relative;
			padding-top:48px;
			padding-bottom:2px;

			&:before{
				content:'';
				display:none;
				position:absolute;
				top:-6px;
				z-index:50;
				right:calc(100% - 96px);
				width:318px;
				height:308px;
				opacity:0.4;
				background-image:url('../images/bg-rodape-logo.png');
				background-repeat:no-repeat;

				@include media-breakpoint-up(lg){
					display:block;
				}

			}

		}

		.rodape-principal__title,
		.rodape-principal__subtitle{
			font-size:14px;
			color:#000000;
			font-family: 'montserratbold';
		}

		.rodape-principal__desc{
			font-size:12px;
			letter-spacing:1px;

			&.desc--logo{
				line-height:1.6;
				font-size:13px;
			}

		}

		.rodape-principal__title{
			position:relative;
			letter-spacing:6px;
			margin-bottom:38px;

			&:after{
				content:'';
				display:block;
				position:absolute;
				top:calc(100% + 8px);
				left:0;
				width:38px;
				height:6px;
				background-color:#000000;

				@include media-breakpoint-down(md){
					left:50%;
					transform:translateX(-50%);
				}

			}

		}

		.rodape-principal__subtitle{
			margin-bottom:4px;
		}

		.container{

			& > .row{

				& > *{
					margin-bottom:42px;
				}

			}

		}

		.col-espaco-esq{

			@include media-breakpoint-up(lg){
				padding-left:36px;

				.media-custom{
					margin-left:-46px;
				}

			}

		}

		.rodape-principal__box{
			margin-bottom:22px;

			&.box--horarios{
				margin-bottom:4px;

				.rodape-principal__subtitle{
					margin-bottom:1px;
				}

			}

		}

	}

	.rodape__copyright{
		background-color:#111111;
		color:#FFF;
		text-align:center;
		font-size:12px;
		position:relative;
		font-weight:bold;
		z-index:80;
		font-family: 'poppinsregular';
		padding-bottom:18px;
		padding-top:27px;

		.rodape-copyright__content{
			max-width:960px;
			margin-left:auto;
			margin-right:auto;

			img{
				max-width:39px;
				margin-left:9px;
				vertical-align:sub;
			}

		}

	}

}