.btn{

	&.btn--responsivo{
		box-shadow:none;
		color:#FFF;

		.bars{
			width:36px;
			height:6px;
			display:inline-block;
			position:relative;
			background-color:#000;

			&,
			&:before,
			&:after{
				transition:all 0.6s linear;
			}

			@include beforeAfter{
				content:"";
				display:block;
				width:100%;
				height:100%;
				background-color:inherit;
				position:absolute;
				left:0;
			}

			&:before{
				top:calc(100% + 4px);
			}

			&:after{
				bottom:calc(100% + 4px);
			}

		}

	}

	&.btn-pill{
		border-radius: 2rem;
	}

	&.btn--padrao-fat,
	&.btn--padrao{
		border:none;
		font-size:14px;
	}	

	&.btn--padrao{
		min-width:188px;
	}

	&.btn--padrao-fat{
		padding:29px 30px;
		font-family: 'montserratbold';
		border-radius:60px;
		min-width:219px;
	}

	&.btn--border-bottom{
		position:relative;
		color:#FFF;
		border-radius:0;
		border-bottom:4px solid currentColor;
		padding-bottom:4px;

		&:before{
			content:'';
			display:block;
			position:absolute;
			bottom:-4px;
			left:0;
			width:0%;
			transition:width 0.6s linear;
			height:4px;
			background-color:$theme;
		}

		&:focus{
			box-shadow:none;
		}

		&:hover{

			&:before{
				width:100%;
			}

		}

		&.active{

			&:before{
				animation:width-ajax 2s ease infinite;
			}

		}

	}

	&.btn--transparent-to-gradient-y-theme{
		color:#FFF;
		// @include gradient-y(transparent,transparent,0%);

		&:hover,
		&:focus,
		&.active{
			box-shadow:none;
			// @include gradient-y(lighten(#FAE453,2%),#FCDB30,0%);
			background-color:#FAE453;
			color:#000;
		}

	}

	&.btn--theme{
		@include button-variant($theme,$theme);
		color:#FFF;
	}

	&.btn--theme-text-black{
		@include button-variant($theme,$theme);
		color:#000;
	}

	&.btn--min-width-317{

		@include media-breakpoint-up(sm){
			min-width:317px;
		}

	}

	&.btn--padrao{
		font-family: 'montserratbold';
		font-size:18px;
	}

	&.btn--radius-60{
		border-radius:60px;
	}

	&.btn--pdg-30-35{
		padding:30px 35px;
	}

}