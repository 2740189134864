.form-fale-conosco{

	form{

		.form-group,
		.input-group{
			margin-bottom:17px;

			.form-control{
				resize:none;
				min-height:53px;
				@include place(#FFF);
				background-color:#222222;
				border-radius:0;
				border-color:#222222;
				font-size:14px;
				font-family: 'montserratregular';
				padding-left:16px;
				@include sombra(#FFF);
			}

			textarea.form-control{
				padding-top:18px;
			}

		}

	}

}

.form-sem-label{

	form{

		.form-group,
		.input-group{
			
			label{
				@include sr-only();
			}

		}

	}

}

.form-pesquisas-topo{

	form{

		.input-group,
		.form-group{

			label{
				display:block;
				width:100%;
				font-size:22px;
			}

			.form-control{
				min-height:42px;
				@include sombra(#CCC);
			}

		}

	}

}