.topo{

	@include media-breakpoint-up(lg){
		
		&:not(.topo--internas){
			position:absolute;
			top:0;
			left:0;
			width:100%;
			z-index:600;
			padding-top:25px;
		}

	}

	.nav-content{

		@include media-breakpoint-down(md){
			position:fixed;
			top:0;
			left:-230px;
			transition:left 0.6s linear;
			width:230px;
			height:100%;
			border-right:1px solid #FFF;
			overflow:auto;
			z-index:1000;
			background-color:#111;
		}

		@include media-breakpoint-up(lg){
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			@include make-container();
			@include make-container-max-widths();
		}

		.nav-content__logo{
			margin-bottom:10px;

			img{
				animation:pulse 2s alternate infinite;
			}

			@include media-breakpoint-down(md){
				max-width:210px;
				padding:30px;
				text-align:center;
				margin-right:0;
			}

			@include media-breakpoint-up(lg){
				margin-right:40px;
				margin-bottom:0;
			}

		}

		.menu-topo{
			color:#FFF;
			list-style-type:none;
			padding-left:0;
			font-size:13px;
			font-family: 'montserratlight';
			margin-bottom:20px;

			@include media-breakpoint-up(lg){
				margin-bottom:0;
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:space-between;
				flex-grow:1;
				max-width:686px;
				margin-right:auto;
			}

			li{

				a{

					@include media-breakpoint-down(md){
						display:block;
						padding:10px 15px;
						border-top:1px solid transparent;
						border-bottom:1px solid transparent;
					}

				}

				&.active,
				&:hover{

					& > a{

						@include media-breakpoint-up(lg){
							color:$theme;
						}
						
						@include media-breakpoint-down(md){
							background-color:#E6AD4C;
							color:#FFF;
							border-color:darken(#E6AD4C,10%);
						}

					}

				}

			}

		}

		.nav-content__info{
			font-size:20px;
			color:#FFF;

			@include media-breakpoint-up(lg){
				display:flex;
				align-items:center;
			}

			.redes-sociais{

				@include media-breakpoint-down(md){
					text-align:center;
					padding:0 15px;
					display:none;
				}

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					align-items:center;
				}

			}

			.nav-content__pesquisas-topo{

				@include media-breakpoint-down(md){
					text-align:center;
					padding:5px 15px;
				}

				@include media-breakpoint-up(lg){
					margin-left:5px;
				}

				& > .btn{
					box-shadow:none;
					padding:0;
					line-height:1;
					border:none;
					color:inherit;
					font-size:inherit;
					vertical-align:text-top;

					@include hover-focus{
						color:$theme;
					}

				}

			}

		}

	}

	.topo__mbl-controls{
		display:flex;
		justify-content:space-between;
		align-items:center;
		box-shadow:0 0 8px rgba(#000,0.5);
		background-color:#FFF;

		.topo-mbl-controls__logo{
			max-width:160px;
			margin-bottom:0;
			padding:10px 15px;
		}

	}

	&.topo--internas{
		background-color:#111111;
		
		@include media-breakpoint-up(lg){
			padding-top:24px;
			padding-bottom:28px;
		}

	}

	&.fx{

		.topo__mbl-controls{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			z-index:400;
			animation:fadeInDown 0.6s linear;
		}

	}

}