@import "_fontfaces";

html{
	scroll-behavior:smooth;
}

body{
	color:#000000;
	font-size:14px;
	font-family: 'montserratregular';
}

#app{
	position:relative;
	overflow:hidden;
}

.modal-pesquisas{

	.modal-dialog{

		.modal-content{
			padding:12px 20px;
		}

	}

}

.rodape a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

.text--white{
	color:#FFF !important;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

@keyframes bolinha1{

	0%{
		transform:translateY(0);
	}

	100%{
		transform:translateY(-40px);
	}

}

@keyframes bolinhaLado{

	0%{
		transform:translateX(0);
	}

	100%{
		transform:translateX(40px);
	}

}

@keyframes bolinhaLadoInverso{

	0%{
		transform:translateX(0);
	}

	100%{
		transform:translateX(-40px);
	}

}

@keyframes bg-cima{

	0%{
		background-position:center 0;
	}

	100%{
		background-position:center -20vh;
	}

}

@keyframes aumento{

	0%{
		transform:scale(1);
	}

	100%{
		transform:scale(1.02);
	}

}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

[data-desligador="true"]{
	display:none;
	background-color:rgba(#000,0.5);
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:700;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

.font--mnt-bld{
	font-family: 'montserratbold' !important;
}

.font--mnt-blk{
	font-family: 'montserratblack' !important;
}

.font--mnt-reg{
	font-family: 'montserratregular' !important;
}

.font--mnt-lgt{
	font-family: 'montserratlight' !important;
}

.font--pops-reg{
	font-family: 'poppinsregular' !important;
}

.font--fnt-12{
	font-size:12px !important;
}

.font--fnt-14{
	font-size:14px !important;
}

.font--fnt-16{
	font-size:16px !important;
}

.font--fnt-20{
	font-size:20px !important;
}

.font--fnt-42{
	
	@include media-breakpoint-up(md){
		font-size:42px !important;
	}

}

@keyframes width-ajax{

	0%{
		width:0;
	}

	100%{
		width:100%;
	}

}

.segura-carousel{
	position:relative;

	.slick-slider{
		position:relative;
		z-index:20;

		a{
			display:block !important;
		}

	}

	.segura-carousel__controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		justify-content:space-between;
		align-items:center;
	}

}

.segura-carousel__clearfix{
	width:100%;

	& > *{
		font-size:17px;
		width:(41 / 17) * 1em;
		height:(41 / 17) * 1em;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;
		background-color:#FFF;
		color:#000;
		border-radius:100%;
		position:relative;
		z-index:300;

		@include hover-focus{
			background-color:darken(#FFF,10%);
		}

		&.ao--contrario{
			background-color:#000;
			color:#FAE453;

			@include hover-focus{
				background-color:lighten(#FFF,10%);
			}
			
		}

		&.float-left{
			left:0;

			@include media-breakpoint-up(lg){
				left:-52px;
			}


			.icon{
				transform:rotate(180deg);
			}

		}

		&.float-right{
			right:0;

			@include media-breakpoint-up(lg){
				right:-52px;
			}

		}

	}

	&.segura-carousel-clearfix--diferente{
		padding-top:26px;
		max-width:54px;
		margin-left:auto;

		& > *{
			font-size:9px;
			width:(24 / 9) * 1em;
			height:(24 / 9) * 1em;
			line-height:(24 / 9) * 1em;

			&.float-left{
				left:0;
			}

			&.float-right{
				right:0;
			}

		}

	}

}

.text--white{
	@extend .text-white;
}

.text--black{
	color:#000 !important;
}

.text--theme{
	color:$theme !important;
}

.text--theme-2{
	color:$theme2 !important;
}

// concerta erro meu (João) de sprites em outros projetos
.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

[data-desligador="true"]{
	display:none;
	background-color:rgba(#000,0.5);
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:700;
	cursor:pointer;
}

@keyframes explodeMidia{

	0%{
		opacity:1;
		transform:scale(1);
	}

	100%{
		opacity:0;
		transform:scale(19);
	}

}

.slick-slider{
	text-align:center;

	.slick-track{
			
		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
		}

	}

}


body{

	&.menu-active{

		.btn--responsivo{

			.bars{
				transform:rotate(45deg);

				@include beforeAfter{
					transform:rotate(-90deg);					
				}

				&:before{
					top:0;
				}

				&:after{
					bottom:0;
				}

			}

		}

		.nav-content{
			left:0;
		}

	}


}