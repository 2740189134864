.card-desafios{
	margin-bottom:45px;
	max-width:256px;
	margin-left:auto;
	margin-right:auto;

	.card-desafios__figure{
		position:relative;
		border-radius:120px 120px 0 0;
		overflow:hidden;
		margin-bottom:0;

		.card-desafios__absolute{
			position:absolute;
			top:0;
			left:0;
			height:100%;
			width:100%;
			display:flex;
			align-items:flex-end;
			justify-content:flex-end;
			text-align:right;
			background-color:rgba(#1F1F1F,0.6);
			transition:background-color 0.6s linear;

			&:before{
				content:'';
				display:block;
				width:9px;
				height:50px;
				position:absolute;
				bottom:0;
				right:18px;
				background-color:#FAE453;
				transition:background-color 0.6s linear;
			}

			.card-desafios__content{
				padding:48px 11px 60px;
				width:100%;

				.card-desafios__title{
					font-size:29px;
					font-family: 'montserratbold';
					color:#FBE454;
					line-height:1;
					transition:color 0.6s linear;
					margin-bottom:6px;
					letter-spacing:4px;

					span{
						display:block;
					}

				}

				.card-desafios__desc{
					color:#FFF;
					font-family: 'montserratlight';
					line-height:1.6;
					@include line-clamp(3);
				}

			}

		}

	}

	&:hover{

		.card-desafios__figure{

			.card-desafios__absolute{
				@include gradient-x(rgba(#F1C23F,0.8),rgba(#FAD52C,0.8),10%);
				animation:fadeIn 0.6s linear;

				&:before{
					background-color:#000102;
				}

				.card-desafios__title{
					color:#FFF;
				}

			}

		}

	}

}

.card-equipe{
	position:relative;
	max-width:318px;
	margin-bottom:37px;
	margin-left:auto;
	margin-right:auto;

	.card-equipe__figure{
		position:relative;
		max-width:256px;
		margin-left:auto;
		margin-right:auto;
		margin-bottom:0;

		img{
			transition:transform 0.6s linear;
		}

		.card-equipe__controls{
			position:absolute;
			bottom:16px;
			right:10px;
			background-color:#000;
			color:#FFF;
			z-index:200;

			& > .btn{
				position:relative;
				z-index:200;
				color:inherit;
				border:none;
				padding:0;
				font-size:20px;
				min-width:39px;
				min-height:39px;
				box-shadow:none;
				transition:transform 0.6s linear;

				&:hover{
					transform:scale(1.2) rotate(6deg);
					color:$theme;
				}

			}

			ul{
				list-style-type:none;
				margin-bottom:0;
				padding-left:0;
				font-size:22px;
				padding-top:14px;
				padding-bottom:14px;

				li{
					margin-bottom:30px;

					a{
						display:block;
						text-align:center;
					}

					&:last-child{
						margin-bottom:0;
					}

					&:hover{
						color:$theme;
					}

				}

			}

		}

	}

	.card-equipe__content{
		height:100%;
		width:100%;
		padding-left:3px;
		color:#FFF;
		transition:color 0.6s linear;
		text-align:left;

		@include media-breakpoint-down(md){
			padding:15px 20px;
		}

		@include media-breakpoint-up(lg){
			transform-origin:center center;
			transform:rotate(90deg);
			position:absolute;
			top:0;
			right:-24px;
		}


		& > *{
			max-width:100%;

			@include media-breakpoint-up(lg){
				@include line-clamp(1);
			}

		}

		.card-equipe__cargo{
			font-size:14px;
		}

		.card-equipe__nome{
			font-size:20px;
			font-family: 'montserratbold';
		}

	}

	&:hover{

		.card-equipe__figure{

			img{
				transform:scale(1.1);
			}

		}

		.card-equipe__content{
			color:#000;
		}

	}

}

.internas{

	.card-equipe{

		&:hover{

			.card-equipe__content{
				color:darken(#D9A128,10%);
			}

		}

	}

}

.card-galeria{
	max-width:463px;
	display:block !important;
	margin-right:0;
	margin-left:0;

	.card-galeria__figure{
		margin-bottom:0;
		position:relative;

		&:before{
			content:'';
			position:absolute;
			top:0;
			left:0;
			width:0;
			z-index:20;
			transition:width 0.6s linear;
			height:100%;
			background-color:rgba($theme,0.7);
		}

	}

	&:hover{

		.card-galeria__figure{
			
			&:before{
				width:100%;
			}

		}

	}

}